<template>
    <div class="row justify-content-center">
        <div class="col-sm-6">
            <h3>請填寫你申請晚歸的時間：</h3>
            <b-calendar
                    block
                    v-model="apply.date"
                    :date-disabled-fn="dateDisabled"
                    label-help="請點選你要選擇的日期"
                    label-no-date-selected="你沒有選擇任何一天"
                    label-current-month="本月"
            />
            <h3>請寫下你申請晚歸的理由：</h3>
            <b-form-group
                    class="mb-0"
                    label-for="textarea-reason"
                    description="理由填寫10字以上；晚歸申請後，請遵守一卡一刷原則，請勿跟在他人後面不刷卡就進出宿舍，且不可夾帶未刷卡者進出宿舍，會根據宿舍公約進行違規記點。"
            >
                <b-form-textarea
                        id="textarea-reason"
                        v-model="apply.reason"
                        :state="apply.reason.length >= 10"
                ></b-form-textarea>
            </b-form-group>
            <b-button block variant="primary" @click="submitApply" :disabled="apply.reason.length < 10 || apply.date === ''">我要申請晚歸</b-button>
        </div>
        <div class="col-sm-6">
            <h3>你已經申請晚歸的日期：<b-button variant="outline-primary" size="sm" @click="loadApplies">重新讀取</b-button></h3>
            <b-table striped hover :items="record.row_data" :fields="record.fields">
                <template v-slot:cell(action)="row">
                    <b-button size="sm" @click="row.toggleDetails">
                        {{ row.detailsShowing ? '還是不要看好了' : '看看當初的理由' }}
                    </b-button>
                </template>
                <template v-slot:row-details="row">
                    <b-card>
                        <p>你的理由：<br>{{row.item.reason}}</p>

                    </b-card>
                </template>
            </b-table>
        </div>

    </div>
</template>

<script>
    import axios from "axios";
    export default {
        name: "CurfewExemptApply",
        data: function(){
          return{
              apply: {
                  reason: "",
                  date:""
              },
              record:{
                  fields:[
                      {
                          label: '申請時間',
                          key: 'apply_time',
                      },
                      {
                          label: '豁免時間',
                          key: 'exempt_day',
                      },
                      {
                          label: '狀態',
                          key: 'status',
                      },
                      {
                          label: '理由',
                          key: 'action'
                      }
                  ],
                  row_data:[]
              }
          }
        },
        methods: {
            dateDisabled(ymd, date) {
                return date < Date.now() - 86400000 || date > Date.now() + 1000*86400*6
            },
            submitApply(){
                axios.post('/api/curfew_exempt/CurfewExemptApply', {
                    date: this.apply.date,
                    reason: this.apply.reason
                }).then((response)=>{
                    window.alert(response.data.msg);
                })
            },
            loadApplies(){
                axios.get('/api/curfew_exempt/CurfewExemptApply')
                .then((response)=>{
                    this.record.row_data = response.data;
                    window.console.log(response.data)
                })
            }
        }
    }
</script>

<style scoped>

</style>
